.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .inputStyle {
    width: 100% !important;
    height: 45px;
    border-radius: 7px;
    border: 0px;
    margin: 0 !important;
    background: #dddddd;
    font-size: 20px;
  }
}
