.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}
