Table,
thead,
tbody,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
