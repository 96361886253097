.formgroup {
  margin-top: 5%;
  border: solid 1px #ccc;
  background-color: #ffffff;
  border-radius: 5px;
}
.formlabel {
  font-size: 12px;
  padding-left: 2%;
  padding-top: 2%;
}
.form-label-otp {
  font-size: 14px;
}
.form-control-otp {
  font-size: 14px;
  outline: 0;
  background-color: transparent;
  padding-top: 0;
}
.formcontrol {
  border: none;
  font-size: 14px;
  outline: 0;
  background-color: #ffffff;
  padding-top: 0;
}
.formcontrol::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c2c9c7;
  opacity: 1; /* Firefox */
}

.formcontrol:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c2c9c7;
}

.formcontrol::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c2c9c7;
}
.text-dark {
  float: right;
  font-size: 14px;
}
.modal-button {
  font-size: 14px;
}
.formcheck {
  margin-top: 5%;
  font-size: 12px;
}
.form-check-radio {
  font-size: 14px;
  margin-left: 10px;
  padding-right: 15px;
}
.form-check-select {
  font-size: 14px;
  margin-left: 10px;
  padding-right: 15px;
}

.button {
  padding-left: 5%;
  padding-right: 5%;
  float: right;
  color: black;
  border: none;
}
.formcontrol:focus {
  outline: none;
  background-color: transparent;
}
.button:active,
.button:hover,
.button:focus {
  outline: none;
}
.filegroup {
  margin-top: 5%;
}
#dropmsg {
  background-color: transparent;
}
.form {
  background-color: transparent;
}
#drop {
  border: solid 0px #ccc;
  background-color: transparent;
}
.form {
  margin-left: 5%;
}
.dropzone {
  min-height: 100px;
  border: 0px solid #4b5563;
  background: transparent;
}

.inputcontrol {
  border: none;
  font-size: 14px;
  outline: 0;
  background-color: #ffffff;
  padding-top: 0;
}
.inputcontrol:focus {
  outline: none;
  background-color: transparent;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
